<template>
    <div class="agregar-residentes" :class="show ? 'activo':'inactivo'">
        <div v-if="vistaSeleccionados" class="row header mx-0 py-2">
            <div class="col-12 d-flex ">
                <i class="icon-arrow-left-circle f-25 mr-4 my-auto cr-pointer" @click="vistaSeleccionados = !vistaSeleccionados" />
                <p class="f-17 f-500 my-auto">Residentes seleccionados ({{ usuariosSeleccionados.length }})</p>
            </div>
        </div>
        <div v-else class="row header mx-0 py-2">
            <div class="col-auto my-auto">
                <i class="icon-arrow-left-circle f-25 my-auto cr-pointer" @click="$emit('close')" />
            </div>
            <div class="col-8 px-0 my-auto">
                <el-input v-model="search" placeholder="Buscar" prefix-icon="el-icon-search" size="small" @input="buscarUser" />
            </div>
            <div class="col-auto my-auto">
                <div class="mas-opciones d-middle-center br-4 f-15 cr-pointer opt" @click="vistaSeleccionados = !vistaSeleccionados">
                    <span class="cantidad px-1">{{ usuariosSeleccionados.length }}</span>
                    <i class="icon-account-checked-outline" />
                </div>
            </div>
        </div>
        <div class="row header mx-0 py-2">
            <div class="col-6 my-auto">
                <el-select v-model="idVivienda" filterable clearable placeholder="Agrupaciones" size="small" class="w-100" @change="getUserViviendas">
                    <el-option
                    v-for="item in viviendas"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
            <div class="col-6 my-auto">
                <el-select v-model="idViviendaHija" filterable clearable placeholder="Viviendas" size="small" class="w-100" @change="getUserViviendasHija">
                    <el-option
                    v-for="item in viviendasHijas"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
        </div>
        <div class="row mx-0 mt-3">
            <div class="col-12">
                <div class="contenedor-residentes overflow-auto scroll-none">
                    <div v-for="(user,key) in arrayUsuarios" :key="key" class="row mx-0 mb-3">
                        <div class="col-10 pl-2">
                            <div class="d-middle">
                                <div class="tipo-user wh-32px d-middle-center rounded-circle mr-2 bg-azulclaro">
                                    <i class="icon-account-outline op-05" />
                                </div>
                                <div class="tres-puntos">
                                    <p class="f-15 f-500"> {{ user.nombre }} </p>
                                    <p class="f-13 text-86 tres-puntos"> {{ user.vivienda }} </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-2 text-right my-auto">
                            <i v-if="vistaSeleccionados" class="icon-close f-13 cr-pointer" @click="removerUser(user,key)" />
                            <i v-if="!user.disable" class="icon-add text-danger f-13 cr-pointer" @click="addUser(user)" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="vistaSeleccionados" class="row mx-0 mt-4 justify-content-center">
            <div class="col-10">
                <button class="btn btn-general f-14 f-300 w-100" @click="agregar">
                    Añadir
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import Chats from '~/services/chats/chat-admin'
import Viviendas from '~/services/viviendas/index'
import {mapGetters} from 'vuex'
export default {
    props:{
        show:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return{
            search:'',
            usuarios:[],
            usuariosSeleccionados:[],
            vistaSeleccionados:false,
            viviendas: [],
            idVivienda: null,
            vivienda: '',
            viviendasHijas: [],
            idViviendaHija: null,
            viviendaHija: ''
        } 
    },
    computed:{
        ...mapGetters({
            grupo:'chatsGrupos/grupoActivo'
        }),
        arrayUsuarios(){
            if(this.vistaSeleccionados){
                return this.usuariosSeleccionados
            }else{
                return this.usuarios
            }
        }
    },
    watch:{
        show(value){
            if(value){
                this.usuarios = []
                this.usuariosSeleccionados = []
                this.vistaSeleccionados = false,
                this.search = ''
            }
        }
    },
    mounted(){
        this.getViviendas();
    },
    methods:{
        async buscarUser(){
            try {
                if(this.search.length > 2 || this.vivienda.length > 0 || this.viviendaHija.length > 0){
                    const busqueda = async() => {
                        let params = { 
                            query : this.search,
                            grupo : this.vivienda,
                            vivienda : this.viviendaHija,
                        }

                        const {data} = await Chats.buscarResidentes(params)
                        this.usuarios = data.usuarios
                    }
                    await this.delay(busqueda)
                }else if(this.search.length === 0){
                    this.usuarios = []
                }
            } catch (e){
                this.errorCatch(e)
            }
        },
        async agregar(){
            try {
                
                if(!this.usuariosSeleccionados.length) return

                const users = _.map(this.usuariosSeleccionados, 'id');

                const form = {
                    usuarios: users,
                    id_grupo: this.grupo.id_grupo
                }

                const {data} = await Chats.agregarResidentesGrupo(form)
                this.$store.dispatch('chatsGrupos/getUsuariosGrupo',this.grupo.id_grupo)
                this.$emit('close')
                this.notificacion('Éxito','Residentes agregados al grupo correctamente','success')

            } catch (error){
                this.errorCatch(error)
            }
        },
        addUser(user){
            user.disable = true
            this.usuariosSeleccionados.push(user)
        },
        removerUser(user,key){
            user.disable = false
            this.usuariosSeleccionados.splice(key,1)
        },
        async getViviendas(){
            try {
                const {data} = await Viviendas.getViviendas()
                this.viviendas = data.data
                this.getViviendasHijas();
            } catch (e){
                this.errorCatch(e)
            }
        },
        async getViviendasHijas(){
            try {
                let params = { 
                    id_padre : this.idVivienda,
                }
                const {data} = await Viviendas.getViviendasHijas(params)
                this.viviendasHijas = data.data
            } catch (e){
                this.errorCatch(e)
            }
        },
        getUserViviendas(id){
            if (id){
                let data = this.viviendas.find(o=>o.id === id);
                this.vivienda = data.nombre;
                this.viviendaHija = '';
                this.getViviendasHijas();
            }else{
                this.vivienda = '';
            }
            this.buscarUser();
        },
        getUserViviendasHija(id){
            if (id){
                let data = this.viviendasHijas.find(o=>o.id === id);
                this.viviendaHija = data.nombre;
            }else{
                this.viviendaHija = '';
            }
            this.buscarUser();
        }
    }
}
</script>
<style lang="scss" scoped>
.info-chat-grupo{
    position: relative;
    .wh-164px{
        max-width: 164px;
        min-width: 164px;
        max-height: 164px;
        min-height: 164px;
    }
    .img-grupo{
        @extend .wh-164px;
        border-radius: 50%;
        border: 2px solid #F5F5F5;
    }
    .contenedor-info{
        height: calc(100vh - 115px);
    }
    .div-divisor{
        height: 5px;
        background: #F5F5F5;
    }
    .icon-navigation{
        transform: rotate( 90deg );
    }
    .tipo-user{
        i.icon-buildings{
            &:before{
                margin-left: 0px;
            }
        }
        i.icon-account-outline{
            font-size: 25px;
        }
    }
    @keyframes slidein {
        from {
            width: 0%;
        }

        to {
            width: 100%;
        }
    }

    .editar-grupo, .agregar-residentes, .seleccionar-admins, .envio-mensajes{
        background: #fff;
        height: calc(100vh - 56px);
        position: absolute;
        top: 0;
        width: 103.9%;
        right: -393.77px;
        display: none;
        &.activo{
            transform: translateX(-379px);
            display: block !important;
            animation-duration: 0.35s;
            animation-name: slidein;
        }
        .header{
            background: #f1f1f1;
        }
        .contenedor-img-grupo{
            @extend .wh-164px;
            border-radius: 50%;
            border: 2px solid #F5F5F5;
            position:relative;
            .bg-hover{
                @extend .wh-164px;
                border-radius: 50%;
                background: #000;
                opacity: 0;
                position: absolute;
                transition: .35s;
            }
            .img-grupo{
                border: none !important;
            }
            &:hover{
                .bg-hover{
                    opacity: 0.7;
                    transition: .45s;

                }
            }
        }
        .contenedor-residentes{
            height: calc(100vh - 206px);
        }
        .contenedor-residentes-admins{
            height: calc(100vh - 135px);
        }
        .mas-opciones.opt{
            position: relative;
            .cantidad{
                position: absolute;
                color: white;
                background: #FF4D59;
                border-radius: 7px;
                font-size: 8px;
                top: -6px;
                right: -10px;
                font-weight: 500;
            }
        }
    }
}
</style>